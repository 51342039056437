import * as msal from "@azure/msal-browser";

class MicrosoftAuthService {
  constructor() {
    this.myMSALObj = new msal.PublicClientApplication({
      auth: {
        clientId: process.env.VUE_APP_MICROSOFT_CLIENT_ID,
        authority: "https://login.microsoftonline.com/common",
        redirectUri: process.env.VUE_APP_OAUTH_REDIRECT_URI,
      },
    });
  }

  signIn() {
    return this.myMSALObj
      .loginPopup({
        scopes: [
          "openid",
          "profile",
          "User.Read",
          "email",
          "Team.ReadBasic.All",
          "Directory.Read.All",
        ],
        prompt: "select_account",
      })
      .then((loginResponse) => {
        return loginResponse;
      });
  }
}

export { MicrosoftAuthService };
