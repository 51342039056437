const prefix = "/admin";

export default [
  {
    meta: {
      title: "Admin",
    },
    path: `/${prefix}`,
    name: "admin.root",
    component: () => import("@/apps/admin/Layout"),
    children: [
      {
        path: "su",
        name: "admin.switchUser",
        component: () => import("@/apps/admin/views/SwitchUser"),
      },
    ],
  },
];
