const prefix = "/tasks";

export default [
  {
    path: `${prefix}`,
    name: "tasks",
    meta: { requiresAuth: false },
    component: () => import("@/apps/tasks/Tasks"),
  },
  {
    path: `${prefix}/:_id/viewer`,
    name: "tasks.single-task.viewer",
    meta: { requiresAuth: false },
    props: true,
    component: () => import("@/apps/tasks/views/Task"),
  },
  {
    path: `${prefix}/:_id/responses/:userId`,
    name: "tasks.single-task.response",
    meta: { requiresAuth: false },
    props: true,
    component: () => import("@/apps/tasks/views/Response"),
  },
  {
    path: `${prefix}/:_id/editor`,
    name: "tasks.single-task.editor",
    meta: { requiresAuth: false },
    props: true,
    component: () => import("@/apps/tasks/views/TaskEditor"),
  },
];
