const prefix = "/dashboard";

export default [
  {
    path: `${prefix}`,
    name: "dashboard",
    meta: { requiresAuth: false },
    component: () => import("@/apps/dashboard/Dashboard"),
  },
];
