const prefix = "/elternsprechtag";
import store from "@/core/store";
import ParentingdayAPI from "@/apps/parentingday/services/ParentingdayAPI";
import { UserService } from "../../login/services/UserService";

async function bookingOpenRouteGuard(to, from, next) {
  const isAuthenticated = store.state.jwt;
  if (!isAuthenticated) {
    next({
      name: "slug.login",
      params: {
        slug: to.params.slug,
      },
    });
    // Exclude countdown route itsef in order to avoid infinite loop!
  } else if (isAuthenticated && from.name != "slug.countdown") {
    if (
      UserService.isAdmin() ||
      process.env.VUE_APP_ALLOW_BOOKING_EVEN_BEFORE_BOOKING_OPEN_AT == "true"
    ) {
      next();
    } else {
      const parentingday = await ParentingdayAPI.getParentingday(
        to.params.slug
      );
      if (new Date(parentingday.bookingOpenAt) > new Date()) {
        next({
          name: "slug.countdown",
          params: {
            slug: to.params.slug,
          },
        });
      } else {
        next();
      }
    }
  } else {
    next();
  }
}

export default [
  {
    meta: {
      title: "Elternsprechtag",
    },
    path: `/${prefix}/:slug`,
    name: "parentingday-root",
    component: () => import("@/apps/parentingday/Layout"),
    children: [
      {
        path: `login`,
        name: "slug.login",
        meta: { public: true },
        component: () => import("@/apps/parentingday/views/Login"),
      },
      {
        path: `login/code`,
        name: "slug.login.code",
        meta: { public: true },
        component: () => import("@/apps/parentingday/views/LoginViaCode"),
      },
      {
        path: `countdown`,
        name: "slug.countdown",
        meta: { public: false },
        component: () => import("@/apps/parentingday/views/Countdown"),
      },
      {
        path: `lehrer`,
        name: "parentingday.teachers",
        meta: { public: false },
        component: () => import("@/apps/parentingday/views/Teacher"),
        beforeEnter: bookingOpenRouteGuard,
      },
      {
        path: `lehrer/me`,
        name: "parentingday.teachers.me",
        meta: { public: false },
        component: () => import("@/apps/parentingday/views/TeacherMe"),
      },
      {
        path: `lehrer/:teacherId`,
        name: "parentingday.teacher.booking",
        props: true,
        meta: { public: false },
        component: () => import("@/apps/parentingday/views/TeacherBooking"),
        beforeEnter: bookingOpenRouteGuard,
      },
      {
        path: `meine-buchungen`,
        name: "parentingday.my-bookings",
        meta: { public: false },
        component: () => import("@/apps/parentingday/views/MyBookings"),
        beforeEnter: bookingOpenRouteGuard,
      },
    ],
  },
];
