import Vue from "vue";
import Vuex from "vuex";
import parentingday from "@/apps/parentingday/store";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const getDefaultState = () => {
  return {
    currentUser: null,
    jwt: null,
  };
};

export default new Vuex.Store({
  namespaced: true,
  modules: {
    parentingday,
  },
  strict: false,

  state: getDefaultState(),
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    },
    setCurrentUser(state, currentUser) {
      state.currentUser = currentUser;
    },
    unsetCurrentUser(state) {
      state.currentUser = null;
    },
    setJwt(state, jwt) {
      state.jwt = jwt;
    },
    unsetJwt(state) {
      state.jwt = null;
    },
  },
  actions: {
    resetState(context) {
      context.commit("resetState");
      context.commit("parentingday/resetState");
    },
  },
  getters: {
    isLoggedIn: (state) => {
      return !!state.currentUser;
    },
    userDisplayName: (state) => {
      if (state.currentUser) {
        return state.currentUser.displayName;
      } else {
        return null;
      }
    },
  },
  plugins: [vuexLocal.plugin],
});
