/**
 * Notice:
 * We use Vues lazy component loading!
 * Components are loaded asnyc in chunks, just when needed.
 *
 * @See:
 * https://vuejs.org/v2/guide/components-dynamic-async.html#Async-Components
 * https://vueschool.io/articles/vuejs-tutorials/async-vuejs-components/
 */

import Vue from "vue";
import Router from "vue-router";
//import { TokenService } from "@/apps/login/services/loginServices";

// Particular routes
import login from "@/apps/login/routes/routes.js";
import dashboard from "@/apps/dashboard/routes/routes.js";
import tasks from "@/apps/tasks/routes/routes.js";
import parentingday from "@/apps/parentingday/routes/routes.js";
import admin from "@/apps/admin/routes/routes.js";
import store from "@/core/store";

Vue.use(Router);

const router = new Router({
  scrollBehavior(to, from, savedPosition) {
    // return desired position
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      meta: {
        title: "schooljet",
        public: true,
      },
      path: "/",
      name: "schooljet",
      component: () => import("@/StartUp"),
      // component: () => import("@/StartUp"),
    },
    ...admin,
    ...login,
    ...dashboard,
    ...tasks,
    ...parentingday,
  ],
});

// Protect views which requires auth
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.jwt;
  if (
    to.matched.some((record) => record.meta.public == false) &&
    !isAuthenticated
  ) {
    next({
      name: "slug.login",
      params: {
        slug: to.params.slug,
      },
    });
  }
  next();
});

export default router;
