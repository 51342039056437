const getDefaultState = () => {
  return {
    slug: null,
    kids: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    },
    setSlug(state, slug) {
      state.slug = slug;
    },
    addKid(state, kidname) {
      kidname = kidname.trim();
      // ensure uniqueness
      if (state.kids.indexOf(kidname) < 0) {
        state.kids.push(kidname);
      }
    },
  },
};
