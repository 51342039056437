import Vue from "vue";
import { format } from "date-fns";
import { de } from "date-fns/locale";

Vue.filter("onlyHourAndMinutes", function (value) {
  if (value) {
    return format(new Date(value), "HH:mm", { locale: de });
  }
});

Vue.filter("dateWithDay", function (value) {
  if (value) {
    return format(new Date(value), "EEEE',' dd.MM", { locale: de });
  }
});

Vue.filter("shortDay", function (value) {
  if (value) {
    return format(new Date(value), "EEEEEE dd.MM", { locale: de });
  }
});

Vue.filter("onlyDay", function (value) {
  if (value) {
    return format(new Date(value), "EEEEEE", { locale: de });
  }
});
