import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./core/store";
// import GAuth from "vue-google-oauth2";
import axios from "axios";
import VueClipboard from "./plugins/vue-clipboard2";

import { MicrosoftAuthService } from "@/apps/login/services/MicrosoftAuthService";
import { UserService } from "@/apps/login/services/UserService";
import "./core/filter/dateFilter";
import "./core/airbrake";

Vue.config.productionTip = false;

let baseUrl = process.env.VUE_APP_API_BASE_URL;
axios.defaults.baseURL = baseUrl;

Vue.prototype.$microsoftAuthService = new MicrosoftAuthService();

// Vue.use(GAuth, {
//   clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
// });

UserService.tryToLoginFromTokenInLocalStorage();

new Vue({
  router,
  vuetify,
  store,
  VueClipboard,
  render: (h) => h(App),
}).$mount("#app");
