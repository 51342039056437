import axios from "axios";

export default {
  cache: {},
  async getParentingday(slug) {
    if (!this.cache[slug]) {
      this.cache[slug] = (await axios.get(`/parentingdays/${slug}`)).data;
    }
    return this.cache[slug];
  },
  async getCurrentParentingday() {
    const parentingdays = (await axios.get(`/parentingdays/`)).data;
    return parentingdays[0];
  },
};
