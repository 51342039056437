import jsonwebtoken from "jsonwebtoken";
import axios from "axios";
import store from "@/core/store";

const UserService = {
  loggedIn() {
    return !!store.state.currentUser;
  },
  isAdmin() {
    return store.state.currentUser.roles.includes("admin");
  },
  isTeacher() {
    return store.state.currentUser.roles.includes("teacher");
  },

  async me() {
    return (await axios.get(`/user/me`)).data;
  },

  // this method should be called during startup
  tryToLoginFromTokenInLocalStorage() {
    const token = this.getToken();
    if (token) {
      this.loginFromJwt(token);
    }
  },

  // login via different sources
  loginViaMicrosoftToken: async function (signInResult) {
    return this.loginViaIdToken(
      "/login/via/microsoft",
      signInResult.idToken,
      signInResult.accessToken
    );
  },
  loginViaGoogleToken: async function (token) {
    return this.loginViaIdToken("/login/via/google", token);
  },
  async loginViaIdToken(backendUrl, idToken, accessToken) {
    const response = await axios.post(backendUrl, {
      idToken,
      accessToken,
    });
    this.loginFromJwt(response.data.jwt);
  },

  loginFromJwt(jwt) {
    this.saveToken(jwt);
    store.commit("setCurrentUser", jsonwebtoken.decode(jwt));
    this.setApiHeader();
  },

  // logout from everything
  logout() {
    store.dispatch("resetState");
    this.unsetApiHeader();
  },

  getToken() {
    return store.state.jwt;
  },
  saveToken(token) {
    return store.commit("setJwt", token);
  },

  // api header handling
  setApiHeader() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.getToken()}`;
  },
  unsetApiHeader() {
    delete axios.defaults.headers.common["Authorization"];
  },
};

export { UserService };
