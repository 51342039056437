import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        // what we had before
        // primary: "#670BFA",
        // primaryVariant: "#657786",
        // secondary: "#657786",
        // secondaryVariant: "#78909C", // Twitter grey: #CFD8DC",

        // https://coolors.co/670bfa-c2e812-06aed5-fff1d0-38182f
        // https://coolors.co/670bfa-06aed5-f0c808-fff1d0-dd1c1a

        // https://coolors.co/670bfa-a9afd1-a1cdf4-fff1d0-38182f
        // primary: "#670BFA",
        // primaryVariant: "#A9AFD1",
        // secondary: "#A1CDF4",
        // secondaryVariant: "#FFF1D0",

        // https://coolors.co/2978a0-315659-253031-90e0f3-44af69
        // primary: "#2978a0",
        // primaryVariant: "#A9AFD1",
        // secondary: "#315659",
        // secondaryVariant: "#90E0F3",

        // https://coolors.co/2978a0-315659-548c2f-f4f1bb-e6ebe0
        // primary: "#2978a0",
        // primaryVariant: "#A9AFD1",
        // secondary: "#51291E",
        // secondary: "#657786",
        // secondary: "#7E7F83",
        // secondary: "#606060",
        // secondary: "#34312D",
        // secondaryVariant: "#90E0F3",

        primary: "#3f98dd",
        primaryVariant: "#A9AFD1",
        // secondary: "#51291E",
        secondary: "#0277BD",
        // secondary: "#7E7F83",
        // secondary: "#606060",
        // secondary: "#34312D",
        secondaryVariant: "#90E0F3",

        background: "#ECEFF1",
        surface: "#FFFFFF",
        surfaceVariant: "#FFFFFF",
        error: "#b00020",
        warning: "#ff9800",
        info: "#607d8b",
        success: "#4caf50",
        onPrimary: "#FFFFFF",
        onSecondary: "#000000",
        onBackground: "#FFFFFF",
        onSurface: "#0277BD",
        onError: "#FFFFFF",
      },
      dark: {
        primary: "#ce93d8",
        primaryVariant: "#3700B3",
        secondary: "#ce93d8",
        secondaryVariant: "#ce93d8",
        background: "#000000",
        surface: "#121212",
        surfaceVariant: "#505050",
        error: "#b00020",
        warning: "#ff9800",
        info: "#607d8b",
        success: "#4caf50",
        onPrimary: "#FFFFFF",
        onSecondary: "#FFFFFF",
        onBackground: "#FFFFFF",
        onSurface: "#FFFFFF",
        onError: "#FFFFFF",
      },
    },
  },
});
