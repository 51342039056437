const prefix = "/login";

export default [
  {
    path: `/${prefix}`,
    name: "login-never-use",
    component: () => import("@/apps/login/Layout"),
    children: [
      {
        path: "",
        name: "login",
        component: () => import("@/apps/login/Login"),
        meta: {
          public: true, // Allow access to even if not logged in
          onlyWhenLoggedOut: true,
        },
      },
      {
        path: `/mit-code`,
        name: "login.withCode",
        component: () => import("@/apps/login/LoginWithCode"),
        meta: {
          public: true, // Allow access to even if not logged in
          onlyWhenLoggedOut: true,
        },
      },
    ],
  },
];
