import Vue from "vue";
import { Notifier } from "@airbrake/browser";

if (process.env.NODE_ENV == "production") {
  var airbrake = new Notifier({
    environment: process.env.NODE_ENV,
    projectId: 383601,
    projectKey: "760c662cb556bb549bd1950771f69214",
  });

  Vue.config.errorHandler = function (err, vm, info) {
    airbrake.notify({
      error: err,
      params: { info: info },
    });
  };
}
